<template>
  <div>
    <top-line-city
      title="ПРАВИЛА ПРОКАТА (АРЕНДЫ) ТРАНСПОРТНОГО СРЕДСТВА"
      image="/BG.png"
    />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="content content-white" ref="print">
                <div class="d-flex justify-content-end pb-3">
                  <div class="news__download" @click="print">
                    <svg
                      width="24"
                      height="20"
                      viewBox="0 0 24 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_204_21412)">
                        <path
                          d="M12 1.87402V14.374"
                          stroke="#272727"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.25 8.75049L12 14.3755L18.75 8.75049"
                          stroke="#272727"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.5 16.874H19.5"
                          stroke="#272727"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_204_21412">
                          <rect width="24" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Скачать</span>
                  </div>
                </div>
                <div v-html="rules" />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <info-sidebar :info="getMainStock" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopLineCity from "@/components/pages/TopLineCity";
import InfoSidebar from "@/components/pages/InfoSidebar";
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js/src";
export default {
  name: "RentalRules",
  components: { InfoSidebar, TopLineCity },
  head: {
    title: function () {
      return {
        inner: this.$route.meta?.title || "Прокат авто",
      };
    },
  },
  data() {
    return {
      rules: "",
    };
  },
  computed: {
    ...mapGetters("content", ["getMainStock"]),
  },
  mounted() {
    this.loadRules();
  },
  methods: {
    async loadRules() {
      try {
        const response = await this.$axios.api.rules({});
        this.rules = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    print() {
      let print = this.$refs.print.innerHTML;
      print = `<div style="padding: 40px"><h1>ПРАВИЛА ПРОКАТА (АРЕНДЫ) ТРАНСПОРТНОГО СРЕДСТВА</h1>${print}</div>`;
      return html2pdf(print, {});
    },
  },
};
</script>

<style scoped lang="scss">
.news__download {
  cursor: pointer;
  @include media(768) {
    span {
      display: none;
    }
  }
  svg {
    //vertical-align: top;
  }
  span {
    padding-left: 10px;
  }
  * {
    transition: all 0.25s;
  }
  &:hover {
    * {
      color: #fe6500;
      stroke: #fe6500;
    }
  }
}
</style>
